<template>
  <b-sidebar
    id="suficienciaFormSidebar"
    right
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    no-close-on-esc
    aria-labelledby="sidebar-no-header-title"
  >
    <template #default="{ hide }">
      <div class="p-3">
        <h4 id="sidebar-no-header-title">Registro de suficiencia presupuestal</h4>

        <!-- Tags -->
        <div class="py-3">
          <x-periodo-fiscal-tag></x-periodo-fiscal-tag>
          <b-badge variant="success" v-if="mes">Mes: {{ getMonthById(mes).value }}</b-badge>
        </div>

        <loading v-if="isWatingForEditItem"></loading>
        <suficiencia-form v-else :mes="mes" @on-result="hide">
          <template #btn-cancel-place="{ clearForm }">
            <b-button
              size="sm"
              variant="black"
              @click="
                clearForm()
                hide()
              "
            >
              Cancelar
            </b-button>
          </template>
        </suficiencia-form>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import SuficienciaForm from '@/components/SuficienciaPresupuestal/SuficienciaForm'

export default {
  name: 'SuficienciaSidebar',

  components: {
    SuficienciaForm
  },

  props: {
    mes: {
      type: Number,
      required: true
    }
  },

  computed: {
    //
    // UI
    ...mapGetters('UIModule', ['getMonthById']),

    //
    // Momentos contables
    ...mapGetters('momentosContablesModule', ['isWatingForEditItem'])
  }
}
</script>
